import { Injectable } from '@angular/core';
import { NgSellyBaseConfig } from './ng-selly-base.config';

@Injectable()
export class NgSellyConfig extends NgSellyBaseConfig  {

  constructor() {
    super();
    this.apiServer = `https://ng-selly-server-pprod.helvetia.it/`;
    this.agentLoginUrl = `${this.apiServer}auth/4Agent`;
    this.liquidatoreLoginUrl = `${this.apiServer}auth/4Operator`;
    this.customerLoginUrl = `${this.apiServer}auth/4Customer`;
    this.shopLoginUrl = `${this.apiServer}auth/asShopLogin`;
    this.remoteSellingLoginUrl = `${this.apiServer}auth/4Customer`;
    this.affinityLoginUrl = `${this.apiServer}auth/4Affinity`;
    this.sistemStart = `08:15:00`;
    this.sistemEnd = `19:40:00`;
    this.closingDay = [6,7];
    this.adobeUrl = 'https://assets.adobedtm.com/launch-EN97a989e0ef704bd8a9b396e6359065de-development.min.js'
    this.consentsUrl = 'https://api.selly-preprod.helvetia.it/api/v2/json/cdn/element.js'
    this.sellyApik8 = 'https://api.selly-preprod.helvetia.it/'
    this.apiServerK8sV1 = `${this.sellyApik8}api/v1/`;
    this.apiServerK8s = `${this.sellyApik8}api/v2/`;
    this.signUp = `/sign-up/`;
    this.consentsCompanyId = '77f0d2f4-afe6-4204-828f-b2e393b4233a';
    this.consentsCompanyTCMId = '203e6930-1098-4167-899a-2b248bffa68b';
  }
}
