import { Component, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { verify } from "jsonwebtoken";
import { NgSellyConfig } from "ng-selly.config";
import * as _ from "lodash";
import { take } from "rxjs/internal/operators";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class UserMockService {
  userState: UserState = {
    logged: true,
    productNode: "09000",
    type: UserType.CUSTOMER,
    loggedInAs: {
      name: "Agente 400",
      email: "h400@helvetia.it",
    },
    notLoggedUserId: 0
  };



  constructor() {
    console.log("[UserMockService]");
  }

  public isAuthenticated(): UserState {
    return this.userState;
  }

  logout() {
    this.userState.logged = false;
  }
}

@Injectable()
export class UserService extends UserMockService {
  private notificationMessage = new BehaviorSubject(null);
  currentnotificationMessage = this.notificationMessage.asObservable();

  userState: UserState = {
    logged: false,
    type: null,
    productNode: null,
    loggedInAs: null,
    loginToken: null,
    notLoggedUserId: 0,
    groups: []
  };

  constructor(private router: Router) {
    super();
    let data = sessionStorage.getItem("userState");
    if (!_.isNil(data)) {
      this.userState = JSON.parse(data);
    }

    this.router.config.unshift(
      {
        path: "auth",
        component: AuthComponent,
      },
      {
        path: "auth/security",
        component: AuthSecurityComponent,
      },
      {
        path: "auth/login",
        component: LoginComponent,
      }
    );
  }

  setUser(data: UserState) {
    console.log('SONO QUI', data)
    this.userState = data;
    this.save();
  }

  save() {
    sessionStorage.setItem("userState", JSON.stringify(this.userState));
  }

  sendNotify() {
    this.notificationMessage.next('sessionExpire')
  }


}

export enum UserType {
  AGENT = "agent",
  DIREZIONE = "direzione",
  CUSTOMER = "customer",
  CUSTOMER_AS_AGENT = "customer_as_agent",
  LIQUIDATORE = "liquidatore",
  ASSHOP = "asShop",
  AFFINITY = "affinity",
}

export class UserState {
  logged: boolean;
  notLoggedUserId: number;
  type?: UserType;
  productNode: string;
  ageDescription?: string;
  loggedInAs: {
    name: string;
    email: string;
    surname?: string;
    username?: string;
    address?: string;
    city?: string;
    district?: string;
    phoneNumber?: string;
    phone_number?: string;
    fiscalCode?: string;
    clientId?: string;
    agreement?: string
  };
  groups?: string[]
  shop?: any;
  tokenInfo?: any;
  loginToken?: any;
}

export class UserStateLoggedIn {
  name: string;
  email: string;
  surname?: string;
  gender?: string;
  username?: string;
  address?: string;
  city?: string;
  district?: string;
  postalCode?: string;
  zip?: string;
  phoneNumber?: string;
  phone_number?: string;
  fiscalCode?: string;
  clientId?: string;
  userId?: number;
  placeOfBirth?: string;
  dateOfBirth?: any;
  origin?: string
  privacy_consent?: string
  service_privacy?: string
  marketing_privacy?: string
  profiling_privacy?: string
}

@Component({
  selector: "selly-security-auth",
  template: "<h1>Errore di autenticazione</h1>",
})
export class AuthSecurityComponent { }

@Component({
  selector: "selly-security-login",
  template: "",
})
export class LoginComponent { }

@Component({
  selector: "selly-auth",
  template: "",
})
export class AuthComponent {
  constructor(
    activatedRoute: ActivatedRoute,
    private router: Router,
    userService: UserService,
    config: NgSellyConfig
  ) {
    const paramsObserver = activatedRoute.queryParams.pipe(take(1));
    paramsObserver.subscribe((params: Params) => {
      const type: string = params.type;

      let data: any;
      try {
        data = verify(params.data, config.publicKey, { algorithms: ["RS256"] });
      } catch (e) {
        console.error(e);
        this.router.navigate([
          {
            outlets: {
              primary: ["auth", "security"],
              header: ["header"],
              footer: ["footer"],
            },
          },
        ]);
        return;
      }
      userService.setUser(data);

      switch (type) {
        case "customer":
        case "asShop":
        case "customer_as_agent":
          this.navigateTo({
            primary: ["selly-web", "catalog"],
            header: ["header"],
            footer: ["footer"],
          });
          break;
        case "agent":
          {
            console.log({ presellingId: params.presellingId });
            const { presellingId } = params;
            if (presellingId) {
              this.navigateTo(
                {
                  primary: ["selly-4agents", "pre-selling"],
                  header: ["header"],
                  footer: ["footer"],
                },
                { presellingId }
              );
            } else {
              this.navigateTo({
                primary: ["selly-4agents", "catalog"],
                header: ["header"],
                footer: ["footer"],
              });
            }
          }
          break;
        case "liquidatore":
          this.navigateTo({
            primary: ["selly-support", "liquidatore"],
            header: ["header"],
          });
          break;
        case "affinity":
          window.location.href = "/selly-4affinity";
/*          
          this.navigateTo({
            primary: ["selly-4affinity"],
            header: ["header"],
          });
*/
          break;
        default:
      }
    });
  }

  navigateTo(outlets: any, queryParams?: any) {
    const originUrl = sessionStorage.getItem("originUrl");
    if (originUrl !== undefined && originUrl !== null) {
      sessionStorage.removeItem("originUrl");
      this.router.navigateByUrl(originUrl);
      return;
    }
    this.router.navigate(
      [
        {
          outlets: outlets,
        },
      ],
      { queryParams }
    );
  }
}
